import React, { useEffect } from 'react'

//
import { checkUrlExists } from '../../build-helpers/utils'
import { BlockRelatedArticles, Layout } from '../components'
import RelatedArticles from '../components/articles/related-articles'
import { MicroCopyContext } from '../components/page-wrapper'
import {
  Author,
  ButtonNew,
  Cta,
  Loader,
  ProgressBar,
  RichText,
  SectionHeader,
  TableOfContent,
  Wistia
} from '../storybook'
import AuthorsSet from '../storybook/Author/AuthorsSet'
import ReadTime from '../storybook/ReadTime'
import { ALIGN, HEADING_TYPE } from '../storybook/SectionHeader'
import Socials from '../storybook/Socials/Socials'
import { getWistiaAspectRatio, shuffleArray } from '../utils/common'
import { graphqlFetch } from '../utils/graphql-fetch'
import { ImageFixed, ImageFluid } from '../utils/image'
import { getMicroCopy } from '../utils/microcopy'
import { buildPageMetaTags } from '../utils/seo'
import { renderRichTextLine } from '../utils/text'
import { getUrl } from '../utils/urls'

const query = `
  query($contentfulId: String!, $locale: String!, $related: [String]!) {
    defaultRelatedArticles: articleCollection(where: { sys: { id_in: $related}  }, limit: 3) {
      items {
        sys {
          id
        }
        title
        articlePublishedDate
        description
        slug
        readingTime
        image {
          url
          height
          width
        }
        video {
          sys {
            id
          }
          videoId
        }
        categoriesCollection(locale: $locale, limit: 2) {
          items {
            sys {
              id
            }
            name
            slug
          }
        }
        enabledLocales
        enabledMarketsCollection(locale: $locale, limit: 20) {
          items {
            sys {
              id
            }
            title
            slugPrefix
          }
        }
      }
    }
    article(id: $contentfulId, locale: $locale) {
      articlePublishedDate
      image {
        url
      }
      video {
        sys {
          id
        }
        videoId
        videoSource
        aspectRatio
        autoPlay
        popover
        transparentBackground
        cover
        muted
        controlsVisibleOnLoad
      }
      ctaBlock {
        title
        text {
          json
        }
        action {
          sys {
            id
          }
          title
          externalUrl
          accessibleText
          relativePath
          icon
          content: contentCollection(limit: 10) {
            items {
              ... on Integration {
                sys {
                  id
                }
                title
                slug
                marketsCollection(limit: 20) {
                  items {
                    title
                    slugPrefix
                  }
                }
              }
              ... on Page {
                sys {
                  id
                }
                title
                slug
                markets: enabledMarketsCollection(limit: 20) {
                  items {
                    title
                    slugPrefix
                  }
                }
              }
              ... on WistiaVideo {
                sys {
                  id
                }
                __typename
                videoId
              }
            }
          }
        }
      }
      body {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              contentType
            }
          }
          entries {
            hyperlink {
              __typename
              ...on Page {
                sys {
                  id
                }
                slug
              }
              ...on Integration {
                sys {
                  id
                }
                slug
              }
              ... on IntegrationCategory {
                sys {
                  id
                }
                slug
              }
							...on Customer {
                sys {
                  id
                }
                slug
              }
              ...on Article {
                sys {
                  id
                }
                slug
                categoriesCollection(limit: 1) {
                  items {
                    sys {
                      id
                    }
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Article = ({ pageContext }) => {
  const {
    contentfulId,
    title,
    description,
    locale,
    langSlugs,
    allMarkets,
    marketsSlugs,
    allLangSlugs,
    metaImage,
    metaTags,
    marketsMetaTags,
    siteMetadata,
    market,
    categories,
    authors,
    readingTime,
    relatedArticles
  } = pageContext

  const microCopyData = React.useContext(MicroCopyContext)
  const { slugPrefix, footer } = market
  const currentSlugPrefix = (slugPrefix || '').replace(/\//gim, '')

  const pageData = { metaTags, metaImage, pageType: 'integration', marketsMetaTags }

  const pageMetaTags = buildPageMetaTags(pageData, siteMetadata)

  const [isLoading, setIsLoading] = React.useState(true)
  const [isLoadingRelatedArticles, setIsLoadingRelatedArticles] = React.useState(true)
  const [templateData, setTemplateData] = React.useState({})
  const [pageMetaTagsData, setPageMetaTagsData] = React.useState(pageMetaTags)
  const [indexOfHeadings, setIndexOfHeadings] = React.useState([])

  pageMetaTags.schemaOrg = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': metaTags.canonical || ''
    },
    headline: title || '',
    description: description || '',
    image: '',
    author: {
      '@type': 'Organization',
      name: authors ? authors.map((author) => author.name).join(', ') : '',
      url: siteMetadata.siteUrl
    },
    publisher: {
      '@type': 'Organization',
      name: 'Deliverect',
      logo: {
        '@type': 'ImageObject',
        url: '/static/Deliverect_Logo_Primary-2f800d5d34235734a3900818cf7fd1d3.png'
      }
    },
    datePublished: templateData?.articlePublishedDate || ''
  }

  useEffect(() => {
    const articlesForCategories = new Set()
    // eslint-disable-next-line no-unused-expressions
    categories?.forEach((cat) => {
      // eslint-disable-next-line no-unused-expressions
      cat?.article?.forEach((art) => {
        const slugPrefixes = Array.isArray(art?.enabledMarkets)
          ? art?.enabledMarkets?.map((i) => i.slugPrefix)
          : []
        if (art?.enabledLocales === null || art?.enabledLocales === true) {
          if (
            art?.enabledMarkets === null ||
            (Array.isArray(art?.enabledMarkets) && art?.enabledMarkets?.length === 0)
          ) {
            articlesForCategories.add(art)
          } else if (
            Array.isArray(art?.enabledMarkets) &&
            slugPrefixes.includes(currentSlugPrefix)
          ) {
            articlesForCategories.add(art)
          }
        }
      })
    })

    const articlesToChoose = shuffleArray([...articlesForCategories])
      .filter((art) => art.contentful_id !== contentfulId)
      .map((article) => article.contentful_id)
      .slice(0, 3)

    graphqlFetch(query, {
      contentfulId,
      locale,
      related: articlesToChoose
    })
      .then(async (res) => {
        const relatedArticlesToSet = res?.data?.defaultRelatedArticles?.items

        const data = {
          body: res?.data?.article?.body,
          image: res?.data?.article?.image,
          video: res?.data?.article?.video,
          ctaBlock: res?.data?.article?.ctaBlock,
          articlePublishedDate: res?.data?.article?.articlePublishedDate,
          defaultRelatedArticles: relatedArticlesToSet
        }
        setTemplateData(data)
        setPageMetaTagsData({
          ...pageMetaTagsData,
          schemaOrg: { ...pageMetaTagsData.schemaOrg, image: data.image?.url }
        })

        setIsLoading(false)

        return data
      })
      .then((article) => {
        // to check whether the related article exists or not
        const relatedArticlesURLs = article.defaultRelatedArticles.map((item) => ({
          id: item.sys.id,
          url: `/${slugPrefix}/blog/${item?.categoriesCollection?.items[0]?.slug}/${item.slug}`.replace(
            /\/{2,}/gim,
            '/'
          )
        }))

        // Check all urls and remove the related articles that don't exist
        Promise.all(relatedArticlesURLs.map(({ id, url }) => checkUrlExists(id, url)))
          .then((results) => {
            const filteredArticles = article.defaultRelatedArticles.filter((relatedArticle) => {
              return results.find((r) => r.id === relatedArticle.sys.id).exists
            })
            article.defaultRelatedArticles = filteredArticles
            setTemplateData(article)
          })
          .finally(() => {
            setIsLoadingRelatedArticles(false)
          })
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      generateTableContentLinks()
    }
  }, [isLoading])

  const generateTableContentLinks = () => {
    const blogBody = document.getElementById('blog-body')

    const h2Tags = blogBody.getElementsByTagName('h2')
    const itemsToRender = []
    for (const i of h2Tags) {
      const idToSet = i.innerText.replace(/[^a-zA-Z]+/g, '')
      i.setAttribute('id', idToSet)
      itemsToRender.push({
        id: idToSet,
        text: i.innerText
      })
    }

    setIndexOfHeadings(itemsToRender)
  }

  const { body, image, video, ctaBlock, defaultRelatedArticles } = templateData

  return (
    <Layout
      metaTags={pageMetaTagsData}
      marketsSlugs={marketsSlugs}
      allLangSlugs={allLangSlugs}
      allMarkets={allMarkets}
      langSlugs={langSlugs}
      header={market.header}
      footerMainNavigation={footer?.mainNavigation}
      footerSocialMedia={footer?.socialMedia}
      footerLegalNavigation={footer?.legalNavigation}
      marketPhoneNo={market.phoneNumber}
      marketPhoneNo2={market.phoneNumber2}
    >
      {!isLoading ? <ProgressBar /> : null}
      <section className="l-section">
        <div className="l-container:12/12 l-container--default-spacing">
          <SectionHeader
            title={title}
            text={renderRichTextLine(description)}
            headingType={HEADING_TYPE.h1}
            align={ALIGN.center}
          />
        </div>
      </section>

      <section className="l-section l-section--overlap l-section--no-padding-top">
        <div className="l-container:12/12 l-container--default-spacing">
          <AuthorsSet
            items={[
              ...(Array.isArray(authors)
                ? authors?.map((author, i) => (
                    <div key={'author_' + i}>
                      <Author
                        name={author?.name}
                        image={author?.avatar ? <ImageFixed image={author?.avatar} /> : undefined}
                      />
                    </div>
                  ))
                : []),
              ...(readingTime
                ? [
                    <div key={'readingTime'}>
                      <ReadTime
                        label={getMicroCopy({
                          key: `global.readingTime`,
                          data: microCopyData,
                          tokens: { min: readingTime || '{min}' }
                        })}
                      />
                    </div>
                  ]
                : [])
            ]}
          />
        </div>
      </section>

      {!isLoading ? (
        <>
          <section className="l-section l-section--no-padding-top">
            <div className="l-container:12/12 l-container--default-spacing">
              {video ? (
                <Wistia
                  videoSource={video?.videoSource}
                  thumbnail={video?.thumbnail || null}
                  videoId={video?.videoId}
                  aspectRatioX={getWistiaAspectRatio(video?.aspectRatio).x}
                  aspectRatioY={getWistiaAspectRatio(video?.aspectRatio).y}
                  cover={video?.cover}
                  autoPlay={video?.autoPlay}
                  controlsVisibleOnLoad={video?.controlsVisibleOnLoad}
                  muted={video?.muted}
                  transparentBackground={video?.transparentBackground}
                  popover={video?.popover}
                  hideControls={video?.hideControls}
                  limitHeight
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  <ImageFluid image={image} width={900} height={600} containImages />
                </div>
              )}
            </div>
          </section>

          <section className="l-section l-section--overlap">
            <div className="l-container:12/12 l-container--default-spacing">
              <div id={'blog-body'} className="l-grid l-grid--default-spacing">
                <div className="u-display:none@to:viewport-12 l-grid__col:3/12">
                  <TableOfContent
                    title={getMicroCopy({
                      key: `blog.tableOfContent`,
                      data: microCopyData,
                      tokens: { min: readingTime || '{min}' }
                    })}
                    items={indexOfHeadings}
                  />
                </div>
                <div className="l-grid__col:12/12 l-grid__col:9/12@at:viewport-12">
                  <RichText
                    content={body}
                    assets={body?.links?.assets?.block}
                    entriesLinks={body?.links?.entries?.hyperlink}
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="l-section l-section--no-padding-top">
            <div className="l-container:12/12 l-container--default-spacing">
              <div className="l-grid l-grid--default-spacing">
                <div className="l-grid__col:12/12">
                  <Socials
                    title={getMicroCopy({ key: `global.shareArticle`, data: microCopyData })}
                    socials={[
                      {
                        id: '0',
                        label: 'Facebook',
                        url:
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                          encodeURIComponent(window.location.href)
                      },
                      {
                        id: '1',
                        label: 'Twitter',
                        url:
                          'http://www.twitter.com/share?url=' +
                          encodeURIComponent(window.location.href)
                      },
                      {
                        id: '2',
                        label: 'Linkedin',
                        url:
                          'https://www.linkedin.com/sharing/share-offsite/?url=' +
                          encodeURIComponent(window.location.href)
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </section>
          {!isLoadingRelatedArticles ? (
            relatedArticles && relatedArticles?.articles?.length ? (
              <BlockRelatedArticles data={relatedArticles} />
            ) : (
              <RelatedArticles articles={defaultRelatedArticles} slugPrefix={slugPrefix} />
            )
          ) : (
            <section className="l-section">
              <div className="l-container:12/12 l-container--default-spacing">
                <Loader />
              </div>
            </section>
          )}

          <Cta title={ctaBlock?.title || ''} text={ctaBlock?.text || ''}>
            {ctaBlock?.action && (
              <ButtonNew url={getUrl(ctaBlock?.action, slugPrefix)}>
                {ctaBlock?.action?.title || ''}
              </ButtonNew>
            )}
          </Cta>
        </>
      ) : (
        <section className="l-section">
          <div className="l-container:12/12 l-container--default-spacing">
            <Loader />
          </div>
        </section>
      )}
    </Layout>
  )
}

export default Article

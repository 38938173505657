const { documentToPlainTextString } = require('@contentful/rich-text-plain-text-renderer')
const trim = require('lodash/trim')
//

const debug = (message) => {
  if (process.env.GATSBY_BUILD_DEBUG === 'true') {
    console.log(message)
  }
}

const getMicroCopy = (allMicroCopy, locale) => {
  const microCopy = {}

  const microCopyNodes = allMicroCopy[locale] || allMicroCopy['en'] || []

  microCopyNodes.forEach((node) => {
    node.values.forEach((value) => {
      microCopy[`${node.key}.${value.key}`] = { value: value.value }
    })
  })

  return microCopy
}

const replacePlaceholders = (inputString, replacements) => {
  if (typeof inputString !== 'string') return inputString
  // Use a regular expression to find and replace the placeholders
  const regex = /{([^}]+)}/g
  const result = inputString.replace(regex, (match, placeholder) => {
    // Check if the placeholder exists in the replacements object
    if (Object.prototype.hasOwnProperty.call(replacements, placeholder.trim())) {
      return replacements[placeholder.trim()]
    }
    // If not found, leave the placeholder unchanged
    return match
  })

  return result
}

const replaceTokens = (text, tokens = {}) => {
  const replacements = {}

  Object.keys(tokens).forEach((key) => {
    replacements[`{${key}}`] = tokens[key]
  })

  if (text && Object.keys(replacements).length) {
    const re = new RegExp(Object.keys(replacements).join('|'), 'gi')
    const result = text.toString().replace(re, (matched) => {
      return replacements[matched]
    })

    return result
  }

  return text
}

const renderPlainRichText = (field) => {
  if (!field) {
    return ''
  }

  const data = field.raw || field.json || null

  if (data) {
    return documentToPlainTextString(field.json)
  }

  return ''
}

const getSiteCanonicalUrl = (pagePath, metaTags = {}) => {
  const canonical = (metaTags && metaTags.canonical) || null

  if (canonical && canonical.startsWith('http')) {
    return canonical
  }

  if (canonical && canonical.startsWith('/')) {
    return `${process.env.GATSBY_SITE_URL}${canonical}`
  }

  if (pagePath.startsWith('/')) {
    return `${process.env.GATSBY_SITE_URL}${pagePath}`
  }

  return `${process.env.GATSBY_SITE_URL}/${pagePath}`
}

const getSiteAlternateUrl = (pagePath) => {
  if (pagePath && pagePath.startsWith('http')) {
    return pagePath
  }

  if (pagePath.startsWith('/')) {
    return `${process.env.GATSBY_SITE_URL}${pagePath}`
  }

  return `${process.env.GATSBY_SITE_URL}/${pagePath}`
}

const getAlternateLanguageCode = (pagePath) => {
  const pathArray = pagePath.split('/')

  if (!pathArray[1]) {
    return 'en'
  }

  if (pathArray[1] === 'us') {
    return 'en-US'
  }

  return pathArray[1].replace('us-', 'en-') || 'en'
}

const TYPE_BASE_PAGE = 'base_page'
const TYPE_CAREERS_PAGE = 'careers_page'
const TYPE_JOB_OFFERS_PAGE = 'job_offers'

let allAlternatesById = {}
let allSlugsById = {}
let homePageAlternates = []

const preparePageDataForAlternate = (node, localeMarkets = []) => {
  if (!allSlugsById[node.contentfulId]) {
    allSlugsById[node.contentfulId] = []
  }

  let slug = node.slug || ''

  if (node?.type === TYPE_CAREERS_PAGE) {
    slug = `careers/${slug}`
  }

  if (node?.type === TYPE_JOB_OFFERS_PAGE) {
    slug = `careers/job-offer/${slug}`
  }

  allSlugsById[node.contentfulId].push({
    slug: slug,
    locale: node?.locale,
    allLocales:
      node?.type === TYPE_CAREERS_PAGE || node?.type === TYPE_JOB_OFFERS_PAGE ? localeMarkets : [],
    globalMarket: node?.globalMarket || {},
    type: node?.type,
    enabledMarkets: node?.enabledMarkets || [],
    isHomePage: node?.isHomePage || false,
    markets:
      localeMarkets
        ?.map((nodeMarket) => nodeMarket?.enabledMarkets)
        ?.reduce((a, b) => {
          if (Array.isArray(a) && Array.isArray(b)) {
            return [...a, ...b]
          }
          return []
        }, []) ||
      node.enabledMarkets ||
      []
  })
}

const buildPageSlug = (slugPrefix, slug, isHomepage = false) => {
  return `/${slugPrefix}${isHomepage ? '' : `/${trim(slug)}`}`
}

const buildArticlesSlug = (slugPrefix, categorySlug, articleSlug) =>
  `/${slugPrefix}/blog/${categorySlug}/${trim(articleSlug)}`

const getAlternateUrlsById = (contentfulId) => {
  if (!allAlternatesById[contentfulId]?.length) {
    allAlternatesById[contentfulId] = {}

    allSlugsById[contentfulId].forEach((item) => {
      const localesOrMarkets =
        item.type === TYPE_CAREERS_PAGE || item.type === TYPE_JOB_OFFERS_PAGE
          ? item.allLocales
          : item.enabledMarkets

      localesOrMarkets.forEach((m) => {
        let slugPrefix = m?.slugPrefix
        const { enabledLanguages } = m
        if (Array.isArray(enabledLanguages) && enabledLanguages.includes(item.locale)) {
          if (item?.type === TYPE_CAREERS_PAGE || item?.type === TYPE_JOB_OFFERS_PAGE) {
            slugPrefix = item?.globalMarket?.[m]?.slugPrefix
          }

          const pPath = buildPageSlug(slugPrefix, item.slug, item.isHomePage)

          allAlternatesById[contentfulId][pPath] = {
            rel: 'alternate',
            hrefLang: getAlternateLanguageCode(pPath),
            href: getSiteAlternateUrl(pPath)
          }
        }
      })
    })
  }

  return allAlternatesById?.[contentfulId] ? Object.values(allAlternatesById[contentfulId]) : []
}

const getHomepageAlternates = (allHomepages) => {
  if (!homePageAlternates.length) {
    Object.keys(allHomepages).forEach((key) => {
      const { slugPrefix, enabledLanguages, locale } = allHomepages[key]
      if (Array.isArray(enabledLanguages) && enabledLanguages.includes(locale)) {
        const pagePath = `/${slugPrefix}`
        homePageAlternates.push({
          rel: 'alternate',
          hrefLang: getAlternateLanguageCode(pagePath),
          href: getSiteAlternateUrl(pagePath)
        })
      }
    })
  }

  return homePageAlternates
}

const resetAlternatesCache = () => {
  allSlugsById = {}
  allAlternatesById = {}
  homePageAlternates = []
}

const getUniqueAlternatesById = (array, property) => {
  const uniqueArray = array.reduce((acc, current) => {
    if (!acc.find((item) => item[property] === current[property])) {
      acc.push(current)
    }
    return acc
  }, [])

  return uniqueArray
}

const checkUrlExists = async (id, url) => {
  return fetch(url, { method: 'HEAD' })
    .then((response) => ({
      id: id,
      exists: response.status !== 404
    }))
    .catch(() => ({
      id: id,
      exists: false
    }))
}

const buildSlugsForAllMarkets = (entries, markets, slugFn = () => {}) => {
  // Set slug per enabled locale
  const marketLocaleToSlug = {}
  entries.forEach((e) => {
    marketLocaleToSlug[e.locale] = e.slug
  })

  const slugs = []

  markets.forEach((market) => {
    const { slugsPair } = market

    Object.keys(slugsPair).forEach((key) => {
      slugs.push({
        slugPrefix: slugsPair[key],
        slug: slugFn(slugsPair[key], marketLocaleToSlug[key])
      })
    })
  })

  return slugs
}

module.exports = {
  debug,
  getMicroCopy,
  replaceTokens,
  renderPlainRichText,
  getSiteCanonicalUrl,
  getSiteAlternateUrl,
  getAlternateLanguageCode,
  preparePageDataForAlternate,
  getAlternateUrlsById,
  buildPageSlug,
  resetAlternatesCache,
  buildArticlesSlug,
  buildSlugsForAllMarkets,
  getHomepageAlternates,
  getUniqueAlternatesById,
  checkUrlExists,
  replacePlaceholders,
  TYPE_BASE_PAGE,
  TYPE_CAREERS_PAGE,
  TYPE_JOB_OFFERS_PAGE
}

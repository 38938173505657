import cn from 'classnames'
import React from 'react'

import IconNew from '../IconNew'
//

const ReadTime = ({ label, classes = '', small = false }) => {
  return (
    <div className={cn('c-readtime', classes)}>
      <IconNew
        classes={`c-readtime__icon ${small ? 'c-readtime__icon--small' : ''}`}
        name={'tabler-clock-hour-4'}
        size={small ? 16 : 20}
      />

      <div className={`c-readtime__label ${small ? 'c-readtime__label--small' : ''}`}>{label}</div>
    </div>
  )
}

export default ReadTime

import cn from 'classnames'
import React from 'react'
//

const AuthorsSet = ({ items, classes = '' }) => {
  return (
    <div className={cn('c-authors-set', classes)}>
      {items?.map((item, i) => {
        return (
          <div key={item?.id || 'author_' + i} className="c-authors-set__item">
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default AuthorsSet
